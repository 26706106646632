export const TES_PARTS = [
    '',
    'א',
    'ב',
    'ג',
    'ד',
    'ה',
    'ו',
    'ז',
    'ח',
    'ט',
    'י',
    'יב',
    'יג',
    'יד',
    'טו',
    'טז',
    'יז'
];